
body {
  /* scale: 100vw; */
  /* overflow: hidden; */
  background-color: #313131;
  /* display:flex;
  flex-direction:column; */
  overflow: hidden;
  /* background: linear-gradient(#0d0d0dcc, #0d0d0dcc), url("grunge.png"); */
}

.outer-container {
  display:flex;
  flex-direction:column;
  align-items: flex-start;
  /* overflow: hidden; */
  min-width: 850px;
}


.content-main {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  /* align-items: center; */
}

.container {
  max-width: 1200px;
  display: flex;
  flex-direction: row;

  flex-wrap: nowrap;
  border-radius: 14px;
  /* background: #4b4b4b; */
  border-bottom: 3px solid #272727;
  border-top: 3px solid #272727;
  filter: drop-shadow(-2px -2px 2px #242424);
  justify-content: space-between;
  margin-bottom:10px;
  background: linear-gradient(#0d0d0d1a, #0d0d0d1a);
  padding: 10px;
}

.container .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  align-items: center;
}

.offset {
  border: 1px solid #434343;
  border-radius: 10px;
  /* background-color: #0e0e0e; */
  filter: drop-shadow(1px 1px 2px #111);
  /* background: linear-gradient(rgba(41,41,41,.7), rgba(41,41,41,.8)), url("grunge.png"); */

}
.container .column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.knobs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  height: 100%;
}

.button-no-lit-container {
  padding: .1rem;
  border: 1px #4e4b4f solid;
  margin: .5rem;
  border-radius: 10px;
  filter: drop-shadow(3px 2px 2px #111);
}
.button-no-lit {
  display: flex;
  min-width: 60px;
  height: 45px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #2a272c;
  background-image: radial-gradient(#333036 20%,#2a272c 80%);
  border-radius: 10px;
  color: #c8c9cd;
  border: 1px solid #423e44;
  /* border-right: 1px solid #282828; */
  font-size:14px;
  font-weight: 500;
  cursor: pointer;
  /* margin: .5rem; */
  filter: drop-shadow(3px 2px 2px #111);
  user-select: none;

  &.wide {
    min-width: 60px;
    height: 25px;
    font-size: 18px;
  }

  &.round {
    border-radius:100px;
    min-width: 50px;
    height: 50px;
    margin: 10px;
    background-color: #c8c9cd;
    filter: drop-shadow(2px 2px 3px #111);
    background-image: radial-gradient(#373339 20%,#2a272c 80%);
    color: #d3cbd8;
    font-size:14px;
    font-weight:500;
    border-right: 1px solid #3c3c3c;

  }
}

/* .button-no-lit:hover {
  background-color: #333035;
  filter: drop-shadow(0px 0px 0px #111);
} */

/* .button-no-lit:active {
  background-color: #333035;
  filter: drop-shadow(0px 0px 0px #111);
} */

.fms-control-block {
  display:flex;
  flex-direction: column;
}

.fms-control {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.isolated {
    flex-basis: 33%;
  }
}


.wide-button {
  display: flex;
  justify-content: center;
}

.fms-control .column {
  display: flex;
  flex-direction: column;
}

.keyboard {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  &.keyrow {
    display: flex;
    flex-direction: row;
  }
}

.numpad {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #515151;
  border-radius: 10px;
  /* background-color: #1c1c1c; */
  filter: drop-shadow(1px 1px 2px #111);
  /* background: linear-gradient(rgba(90,90,90,.3), rgba(110,110,110,.3)), url("grunge.png"); */
  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;

  }
}

/*  Lit Button */
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: .5rem;
}

.status-indicator {
  width: 30px;
  height: 4px;
  background-color: #444; /* Default off state */
  border-radius: 4px;
  /* margin-bottom: 5px; */
  transition: background-color 0.3s ease;
  filter: drop-shadow(-1px -1px 2px #111);
  border-right: 1px solid #5e5e5e;
  border-left: 1px solid #111111;
  /* border: 1px solid #4d4d4d; */
}

.button-lit-container {
  padding: .1rem;
  border: 1px #4e4b4f solid;
  margin: .5rem;
  border-radius: 10px;
  filter: drop-shadow(3px 2px 2px #111);
}

.button-lit {
  display: flex;
  min-width: 65px;
  height: 45px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #2a272c;
  border-radius: 10px;
  color: #c8c9cd;
  border: 1px solid #423e44;
  /* border-right: 1px solid #636363; */
  font-size:14px;
  font-weight: 500;
  filter: drop-shadow(3px 2px 2px #111);
  background-image: radial-gradient(#373339 20%,#2a272c 80%);
  /* margin: .5rem; moved to container */
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease;
}

.active {
  background-image: radial-gradient(#2c292d 20%,#3a373c 80%);
  /* filter: drop-shadow(0px 0px 0px #111); */
  filter: drop-shadow(-1px -1px 2px #111);
  transition: background-color 0.3s ease;
  border-right: none;
  border-left: none;
}

.status-indicator.active {
  background-color: #ebebeb; /* Green when active */
  background-image: radial-gradient(#dfdce0 20%,#e5e3e7 80%);

  -webkit-box-shadow:0px 0px 20px 3px rgba(227,227,227,1);
  -moz-box-shadow: 0px 0px 20px 3px rgba(227,227,227,1);
  box-shadow: 0px 0px 20px 3px rgba(227,227,227,1);
}

/* Rocker Switch */
.switch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 5px; */
  /* margin: .5rem; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.switch-label {
  font-size: 12px;
  color: #c8c9cd;
  font-weight: 700;
  margin-bottom: -5px;
}

.rocker-switch-container {
    padding: .2rem;
    border: 1px #4e4b4f solid;
    margin: .5rem;
    border-radius: 10px;
    filter: drop-shadow(3px 2px 2px #111);
}
.rocker-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  height: 100px;
  /* background-color: #c8c9cd; */
  /* background-color: #2a272c; */
  background-color: #aca4b0;
  border-radius: 10px;
  border: 1px solid #474349;
  filter: drop-shadow(3px 2px 2px #111);
  position: relative;
  overflow: hidden;
  user-select: none;
}

.rocker-button {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #454647;
  /* color: #2a272c; */
  cursor: pointer;
  user-select: none;
  transition: transform 0.1s ease-in-out, background-color 0.2s;
  /* background-image: radial-gradient(#373339 20%,#2a272c 80%); */
  background-image: radial-gradient(#9a969c 20%,#8e8792 80%);
  border: 1px solid #423e44;
  border-right: 1px solid #636363;
  font-size:16px;
  font-weight: 500;

}

.rocker-button:active {
  background-color: #1e1c20;
}

.up {
  border-bottom: 1px solid #444;
  user-select: none;
}

.down {
  border-top: 1px solid #444;
  user-select: none;
}

.pressed-up {
  transform: translateY(-3px);
  background-color: #3a3a40;
}

.pressed-down {
  transform: translateY(3px);
  background-color: #3a3a40;
}

.ctr-button-container {
  padding: .1rem;
  border: 1px #4e4b4f solid;
  margin: .5rem;
  border-radius: 5px;
  filter: drop-shadow(3px 3px 2px #111);
}

.ctr-button {
  width: 60px;
  height: 25px;
  /* background-color: #2a272c; */
  background-image: radial-gradient(#9a969c 20%,#8e8792 80%);
  border-radius: 5px;
  color: #353537;
  border: 1px solid #474349;
  border: 1px solid #423e44;
  border-right: 1px solid #494949;
  font-size:12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  filter: drop-shadow(2px 2px 4px #111);
  transition: background-color 0.2s ease-in-out;
  user-select: none;
}

.ctr-button:active {
  background-color: #3a3a40;
}

.spacer {
  border-right: 1px solid #767676;
  width: 1px;
  filter: drop-shadow(1px 1px 2px #111);
  -webkit-box-shadow:0px 0px 60px 1px rgba(227,227,227,1);
  -moz-box-shadow: 0px 0px 60px 1px rgba(227,227,227,1);
  box-shadow: 0px 0px 60px 1px rgba(227,227,227,1);
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #525252;
  font-family:'Courier New', Courier, monospace;
}